import type { ThunkDispatch, AnyAction } from '@reduxjs/toolkit'
import { TonConnectUI, toUserFriendlyAddress } from '@tonconnect/ui'
import { NETWORKS } from '../../../constants/types'
import { envConfig } from '../../../env/envConfig'
import { setupTonProvider } from '../providers/tonProvider'
const { appURL, environment } = envConfig

export const connectToTon = async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  const tonConnectUI = new TonConnectUI({
    manifestUrl:
      import.meta.env.MODE === 'production'
        ? `https://${appURL}/tonconnect-manifest.json`
        : 'http://localhost:3000/tonconnect-manifest.json',
    buttonRootId: 'ton-connect',
  })
  const connectedWallet = await tonConnectUI.connectWallet()
  if (!connectedWallet) {
    throw new Error('Something went wrong when connecting your Tron wallet.')
  }
  setupTonProvider(tonConnectUI)
  const rawAddress = connectedWallet.account.address
  const address = toUserFriendlyAddress(rawAddress, environment !== 'production').toString()

  return {
    address,
    networkId: 'TON',
    domain: '',
    connectedChain: NETWORKS.TON,
  }
}
