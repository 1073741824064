import { envConfig } from '../../env/envConfig'
import { NameServices } from '../nameServices/nameServices'
import { ContractInteractionTabId } from './contractsInteractions.types'

export const ZERO_HASH = '0x0000000000000000000000000000000000000000000000000000000000000000'
export const GM_CONTRACT_BYTECODE_HASH = '0x010000a977b1099c2773bc35f366dc5a7581b28c5f5ecce63dd55959115bc1fd'
export const ZKSYNC_CONTRACT_DEPLOYER_ADDRESS = '0x0000000000000000000000000000000000008006'
export const CONTRACTS_INTERACTIONS_EARN_CARD_ITEMS_LIMIT = 3
export const CONTRACTS_INTERACTIONS_POPULAR_NEW_LIMIT = 5
export const CONTRACTS_INTERACTIONS_HISTORY_LIMIT = 10
export const CONTRACTS_INTERACTIONS_YOUR_LIMIT = 10

export const CONTRACTS_INTERACTIONS_LS_KEY_SELECTED_CHAIN = 'contracts-interactions-chain'

export const CONTRACTS_INTERACTIONS_CHAINS = Object.keys(envConfig.CONTRACTS_INTERACTIONS_CHAINS_CONFIG)

export const CHAIN_TWITTER_HANDLE_MAPPING: Record<string, string> = {
  SCROLL: '@Scroll_ZKP',
  ZKSYNC: '@zksync',
  LINEA: '@LineaBuild',
  ZKEVM: 'Polygon zkEVM',
  BASE: '@base',
}

export const CHAIN_NS_MAPPING: Record<string, NameServices> = {
  ZKSYNC: NameServices.ZKNS,
}

export const CI_POPULAR_TAB_IDS = [ContractInteractionTabId.POPULAR_ALL_TIME, ContractInteractionTabId.POPULAR_WEEKLY]

// Specific in order to be the same height as the rendered table when 5 elements are loaded
// Makes the page not jump when table tada is loaded for the first time
export const CI_INTERACTIONS_TABLES_LOADER_MIN_HEIGHT = 363
