import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { FreeBridge, Referral, ReferralConfig, User } from '@rhinofi/dvf-referrals-api-spec'
import { envConfig } from '../env/envConfig'
import { heapEvents } from '../constants/heapEvents'
import { addAuthorizationHeader } from './auth/addAuthorizationHeader'
import { trackHeapEvent } from './apiService'

const endpointsWithAuth = [
  'getReferralUser',
  'getReferralUserFreeBridges',
  'createReferral',
  'createAffiliateId',
  'createMarketingTracking',
]

export const referralApi = createApi({
  reducerPath: 'referralApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${envConfig.tradingApi}/referrals`,
    prepareHeaders: async (headers: Headers, { endpoint }: { endpoint: string }) => {
      if (endpointsWithAuth.includes(endpoint)) {
        return await addAuthorizationHeader(headers, endpoint)
      }
      return headers
    },
  }),
  tagTypes: ['Authenticated', 'ReferralUser', 'FreeBridges', 'UserRewards', 'ReferralRecord', 'AlphaKeyEligibility'],
  endpoints: (builder) => ({
    getReferralConfig: builder.query<ReferralConfig, void>({
      query: () => ({ url: '/config' }),
    }),
    getReferralUser: builder.query<User, string>({
      query: (user) => ({ url: `/users/${user}` }),
      providesTags: ['Authenticated', 'ReferralUser'],
    }),
    getReferralUserFreeBridges: builder.query<Array<FreeBridge>, string>({
      query: (user) => ({ url: `/users/${user}/freeBridges` }),
      providesTags: ['Authenticated', 'FreeBridges'],
    }),
    getReferral: builder.query<Referral, string>({
      query: (referee) => ({ url: `/referrals/${referee}` }),
      providesTags: ['ReferralRecord'],
    }),
    isReferralIdValid: builder.query<boolean, string>({
      query: (referralId) => ({ url: `/isReferralIdValid/${referralId}` }),
    }),
    getCompletedReferralsCount: builder.query<number, string>({
      query: (referrer) => ({ url: `/completedReferralsCount/${referrer}` }),
    }),
    getAlphaKeyEligibility: builder.query<boolean, string>({
      query: (user) => ({ url: `/goldenTicketEligibility/${user}` }),
      providesTags: ['AlphaKeyEligibility'],
    }),
    createAffiliateId: builder.mutation<void, { user: string; affiliateId: string }>({
      query: ({ user, affiliateId }) => ({
        url: `/affiliates/${user}/affiliateId/${affiliateId}`,
        method: 'POST',
      }),
    }),
    createMarketingTracking: builder.mutation<void, { user: string; campaignId: string }>({
      query: ({ user, campaignId }) => ({
        url: `/marketingTracking/${user}/campaignId/${campaignId}`,
        method: 'POST',
      }),
    }),
    createReferralId: builder.mutation<void, { user: string }>({
      query: ({ user }) => ({
        url: `/users/${user}/referralId`,
        method: 'POST',
      }),
      invalidatesTags: ['ReferralUser'],
      transformResponse: (response: void, meta, arg) => {
        trackHeapEvent(heapEvents.referralLinkUnlocked)
        return response
      },
    }),
    createReferral: builder.mutation<void, { referee: string; referralId?: string }>({
      query: ({ referee, referralId }) => ({
        url: '/referrals',
        method: 'POST',
        body: {
          referralId,
          referee,
        },
      }),
      invalidatesTags: ['ReferralRecord', 'AlphaKeyEligibility'],
      transformResponse: (response: void, meta, arg) => {
        if (arg.referralId) {
          trackHeapEvent(heapEvents.referralCreated, {
            referralId: arg.referralId,
          })
        }
        return response
      },
    }),
  }),
})

export const {
  useGetReferralQuery,
  useGetReferralUserQuery,
  useGetReferralUserFreeBridgesQuery,
  useGetReferralConfigQuery,
  useIsReferralIdValidQuery,
  useGetAlphaKeyEligibilityQuery,
  useGetCompletedReferralsCountQuery,
  useCreateReferralMutation,
  useCreateReferralIdMutation,
  useCreateAffiliateIdMutation,
  useCreateMarketingTrackingMutation,
} = referralApi
