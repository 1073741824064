import { CallData, Contract } from 'starknet'
import type { RpcProvider } from 'starknet'
import Decimal from 'decimal.js'
import type { UnifiedTokenRegistryState } from '../../../../reducers/types/UnifiedTokenRegistryState'
import type { AddressEntry } from '../../../ethereum/blockchainBalances'
import { NETWORKS } from '../../../../constants/types'
import type { MulticallBalances } from '../../../ethereum/multicall'
import { envConfig } from '../../../../env/envConfig'
import { getPublicStarknetProvider } from '../../providers/starknetProvider'
import { getParadexProvider } from '../../providers/paradexProvider'
import starknetMulticallABi from './abis/starknetMulticallABI.json'

const { nonEVMMulticallContracts } = envConfig

const pickStarknetProvider = (starknetChain: string) => {
  if (starknetChain === NETWORKS.STARKNET) {
    return getPublicStarknetProvider()
  } else if (starknetChain === NETWORKS.PARADEX) {
    const { provider } = getParadexProvider()
    return provider as unknown as RpcProvider
  }

  throw new Error(`No provider for ${starknetChain}`)
}

export const getStarknetBalances = async (
  secondaryWalletAddress: string,
  tokenAddresses: AddressEntry[],
  tokenRegistry: UnifiedTokenRegistryState,
  starknetChain: string,
) => {
  if (!secondaryWalletAddress) {
    return {}
  }
  try {
    const multicallContractAddress = (nonEVMMulticallContracts as Record<string, string>)[starknetChain]
    if (!multicallContractAddress) {
      throw new Error(`No multicall contract address for ${starknetChain}`)
    }
    const starknetProvider = pickStarknetProvider(starknetChain)
    const contract = new Contract(starknetMulticallABi, multicallContractAddress, starknetProvider)
    const aggregation = await contract.aggregate(
      tokenAddresses.map(({ address }) => ({
        to: address,
        selector: '0x2e4263afad30923c891518314c3c95dbe830a16874e8abc5777a9a20b54c76e',
        calldata: CallData.compile({
          account: secondaryWalletAddress,
        }),
      })),
    )

    // Fix types for below lines
    const results = Object.values(aggregation)
    const balances = tokenAddresses.reduce((accumulator, { token }: { token: string }, index: number) => {
      if (!accumulator[starknetChain]) {
        accumulator[starknetChain] = {}
      }
      const result = results[1] as bigint[][]
      const balance = Number(result[index][0])
      const decimals = tokenRegistry[token]?.decimals || 18
      accumulator[starknetChain][token] = {
        balance: new Decimal(balance).div(10 ** decimals).toString(),
        token,
      }
      return accumulator
    }, {} as MulticallBalances)

    return balances
  } catch (error) {
    console.error(error)
    // Fail silently
    return {}
  }
}
