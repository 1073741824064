import starkExV2Abi from '@rhino.fi/client-js/src/api/contract/abi/StarkExV2.abi.json'
import { toBN } from '@rhino.fi/dvf-utils'
import Decimal from 'decimal.js'
import type { AbiItem } from 'ethereum-multicall/dist/esm/models'
import type { BN } from 'ethereumjs-util'
import isNull from 'lodash/isNull'
import map from 'lodash/map'
import mean from 'lodash/mean'
import noop from 'lodash/noop'
import type { TransactionConfig, TransactionReceipt } from 'web3-eth/types'
import { showGasPriceModal } from '../actions/commonActions'
import type { ChainGasFeed, SupportedWallets } from '../constants/types'
import { WALLETS } from '../constants/types'
import { envConfig } from '../env/envConfig'
import { BridgeErrorType } from '../pages/Bridge/types/BridgeWidget.types'
import { tokenAbi } from './abis/tokenAbi'
import { getSafeGasPrice, getSafeGasPriceForSidechain } from './apiService'
import { getDvf } from './dvfClient'
import type { DvfClientInstance } from './dvfClient/DvfClientInstance'
import { signPermit } from './eip712Service'
import { getChainNameForNetworkId, getNetworkIdForChainName, isNonEVMChain } from './ethereum/chainProviders'
import { randomId } from './helperService/randomId'
import { provideContractData, provideContractDataForAddress } from './wallets/ledgerService'
import type { WalletPayload } from './wallets/wallet'
import { getProvider, getWeb3 } from './wallets/wallet'
import { pollTxReceiptFromOurProvider } from './helperService/pollTxReceiptFromOurProvider'
import { checkNonEVMDepositAllowance } from './secondaryWallet/checkNonEVMDepositAllowance'
import { approveNonEVMDeposit } from './secondaryWallet/approveNonEVMDeposit'

const { etherscanApiUrl, network, tokenPermitConfig, addNetworkParametersPerNetworkId } = envConfig

export const getBalance = async (account: string) =>
  new Promise<string>((resolve, reject) => {
    const { DEFAULT: web3 } = getWeb3()
    web3.eth.getBalance(account, (err: unknown, balanceWei: string | BN) => {
      if (err) {
        resolve('0')
      }
      resolve(web3.utils.fromWei(balanceWei || '0').toString())
    })
  })

export const getNativeBalance = async (account: string, chain = 'ETHEREUM', decimals = 18): Promise<string> =>
  new Promise((resolve, reject) => {
    const web3 = getWeb3()
    const timeoutId = setTimeout(() => {
      reject(new Error('Too long to fetch native balance.'))
    }, 6000)
    web3[chain].eth.getBalance(account, (err: unknown, balanceWei: string) => {
      clearTimeout(timeoutId)
      if (err) {
        reject(err)
      }
      const balance = new Decimal(balanceWei || 0).div(10 ** decimals).toString()
      resolve(balance)
    })
  })

export const getBalanceWei = async (account: string) => getNativeBalance(account)

export const getErc20Balance = async (account: string, token: string) => {
  const { DEFAULT: web3 } = getWeb3()
  const tokenContract = new web3.eth.Contract(tokenAbi, token)
  return tokenContract.methods.balanceOf(account).call()
}

export const getWeb3ProviderForChain = (chain: string) => {
  const web3 = getWeb3()
  const web3ProviderForChain = web3[chain]

  if (!web3ProviderForChain) {
    throw new Error(`No web3 provider for chain ${chain}`)
  }

  return web3ProviderForChain
}

export const getTokenContractForChain = (tokenAddress: string, chain: string) => {
  const web3ProviderForChain = getWeb3ProviderForChain(chain)
  return new web3ProviderForChain.eth.Contract(tokenAbi, tokenAddress)
}

export const getErc20BalanceForChain = async (account: string, tokenAddress: string, chain: string) => {
  const tokenContract = getTokenContractForChain(tokenAddress, chain)
  return tokenContract.methods.balanceOf(account).call()
}

export const getNetworkId = async () => {
  const web3 = getWeb3()
  if (web3.DEFAULT) {
    return web3.DEFAULT.eth.getChainId()
  } else if (window.ethereum) {
    return parseInt(window.ethereum.networkVersion)
  } else {
    return network
  }
}

export const checkIfProviderIsLate = async () => {
  const res = await fetch(etherscanApiUrl + '/api?module=proxy&action=eth_blockNumber')
  const data = await res.json<{ result: string }>()
  const web3 = getWeb3()
  const etherscanBlock = parseInt(data.result, 16)
  const currentBlock = await web3.ETHEREUM.eth.getBlockNumber()
  if (etherscanBlock - currentBlock >= 5) {
    console.warn('Provider block difference: ', etherscanBlock - currentBlock)
  }
  return etherscanBlock - currentBlock
}

export const checkAllowanceForAddress = async (
  address: string,
  tokenContractAddress: string,
  spenderContractAddress: string,
) => {
  const web3 = getWeb3()
  const tokenContract = new web3.ETHEREUM.eth.Contract(tokenAbi, tokenContractAddress)
  return tokenContract.methods.allowance(address, spenderContractAddress).call()
}

export const MAX_APPROVAL_VALUE = (2 ** 256 - 1).toString(16)

export const approveTokenForAddress = async (
  address: string,
  tokenContractAddress: string,
  spenderContractAddress: string,
  chain: string,
) => {
  const web3 = getWeb3()
  const tokenContract = new web3.DEFAULT.eth.Contract(tokenAbi, tokenContractAddress)
  const amount = (2 ** 256 - 1).toString(16)
  const chainId = getNetworkIdForChainName(chain)
  if (!chainId) {
    throw new Error('Chain ID not found')
  }
  return tokenContract.methods.approve(spenderContractAddress, amount).send({
    from: address,
    value: '0',
    chainId: `0x${chainId.toString(16)}`,
  })
}

const getTokenPermitSignature = async (
  wallet: {
    address: string
    walletType: SupportedWallets
    path?: string
  },
  permitConfig: {
    version: string
    name?: string
    nonceGetter?: (dvf: DvfClientInstance, tokenAddress: string, ownerAddress?: string) => Promise<string>
  },
  tokenAddress: string,
  spender: string,
) => {
  const dvf = await getDvf()
  const { id: chainId } = await dvf.eth.getNetwork()
  const walletAddress = wallet?.address.toLowerCase()
  const name = permitConfig.name || (await dvf.contract.getNameForAddress<Promise<string>>(tokenAddress))
  const nonce = permitConfig.nonceGetter
    ? await permitConfig.nonceGetter(dvf, tokenAddress, walletAddress)
    : await dvf.contract.getPermitNonceForAddress<Promise<string>>(tokenAddress, walletAddress)
  const { version } = permitConfig
  return signPermit({
    wallet,
    chainId,
    spender,
    tokenAddress,
    name,
    nonce,
    version,
  })
}

type TransactionHashCallbackType = (error: unknown, hash?: string) => void
export const approveTokensForEthereumDeposit = async (
  wallet: WalletPayload,
  token: string | null,
  tokenAddress: string,
  amount: number | null | string,
  isProxy = true,
  transactionHashCb: TransactionHashCallbackType = noop,
) => {
  const dvf = await getDvf()
  const spender = isProxy
    ? dvf.config.DVF.registrationAndDepositInterfaceAddress
    : dvf.config.DVF.starkExContractAddress

  if (wallet?.walletType === WALLETS.LEDGER) {
    await provideContractData(dvf, token)
  }
  if (wallet?.walletType === WALLETS.METAMASK || wallet?.walletType === WALLETS.LEDGER) {
    // Referencing tokens supporting https://eips.ethereum.org/EIPS/eip-2612
    // for which implementation can vary slighly (hence the need for a config)
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const permitConfig = tokenPermitConfig?.ETHEREUM[token]
    if (permitConfig) {
      return getTokenPermitSignature(wallet, permitConfig, tokenAddress, spender)
    }
  }

  let options: {
    transactionHashCb?: TransactionHashCallbackType
  } = {}
  if (transactionHashCb) {
    options.transactionHashCb = transactionHashCb
  }

  // Returns a Promuse that resolves if successful without returning anything
  return dvf.contract.approve<Promise<undefined>>(token, amount, spender, undefined, options).then(noop)
}

export const getBridgeContractAddressForChain = async (chain: string) => {
  const dvf = await getDvf()
  return dvf.config.DVF.bridgedDepositContractsPerChain
    ? dvf.config.DVF.bridgedDepositContractsPerChain[chain]
    : dvf.config.DVF.bridgeConfigPerChain[chain] && dvf.config.DVF.bridgeConfigPerChain[chain].contractAddress
}

export const approveTokensForBridgedDeposit = async (
  token: string,
  amount: number | string | null,
  chain: string,
  walletType: string | undefined,
) => {
  const dvf = await getDvf()
  const bridgedDepositContract = await getBridgeContractAddressForChain(chain)
  if (walletType === WALLETS.LEDGER) {
    await provideContractData(dvf, token)
  }
  if (isNonEVMChain(chain)) {
    return approveNonEVMDeposit(token, chain)
  }
  return dvf.contract.approve(token, null, bridgedDepositContract, chain)
}

export const approveTokensForCrossChainDeposit = async (
  tokenAddress: string,
  spenderAddress: string,
  chain: string,
  walletType: string | undefined,
) => {
  const dvf = await getDvf()
  const amount = (2 ** 96 - 1).toString(16)

  if (walletType === WALLETS.LEDGER) {
    await provideContractDataForAddress(dvf, tokenAddress)
  }

  return dvf.eth.send(tokenAbi, tokenAddress, 'approve', [spenderAddress, amount], null, { chain })
}

export const checkDepositAllowance = async (tokenAddress: string, contractAddress: string, chain = 'ETHEREUM') => {
  if (tokenAddress === '' || contractAddress === '') {
    return false
  }
  if (tokenAddress === '0x0000000000000000000000000000000000000000') {
    return true
  }

  const dvf = await getDvf()
  const account = dvf.get('account')
  const contract = getTokenContractForChain(tokenAddress, chain)
  const result = await contract.methods.allowance(account, contractAddress).call()

  return toBN(result)
}

export const checkBridgeDepositAllowance = async (token: string, chain: string, nonEVMWalletAddress: string) => {
  const dvf = await getDvf()
  const tokenAddressForChain = dvf.config.tokenRegistry[token].tokenAddressPerChain[chain]
  const bridgedDepositContract = await getBridgeContractAddressForChain(chain)
  if (isNonEVMChain(chain)) {
    return checkNonEVMDepositAllowance(tokenAddressForChain, bridgedDepositContract, chain, nonEVMWalletAddress)
  }

  return checkDepositAllowance(tokenAddressForChain, bridgedDepositContract, chain)
}

export const checkEthereumDepositAllowance = async (token: string, isProxy = true) => {
  if (token === 'ETH') {
    return true
  }
  const dvf = await getDvf()
  const tokenAddressForChain = dvf.config.tokenRegistry[token]?.tokenAddressPerChain.ETHEREUM ?? ''

  return checkDepositAllowance(
    tokenAddressForChain,
    isProxy ? dvf.config.DVF.registrationAndDepositInterfaceAddress : dvf.config.DVF.starkExContractAddress,
  )
}

// options.transactionHashCb is passed from higher-order components when they need
// to access the txHash before the transaction is mined. Ex: Deposits
const makeOnTransactionHashHandler =
  (options: { transactionHashCb?: TransactionHashCallbackType }) => (hash: string) => {
    if (options.transactionHashCb) {
      options.transactionHashCb(null, hash)
    }
  }

const makeErrorHandler = (options: { transactionHashCb?: TransactionHashCallbackType }) => (error: unknown) => {
  if (options.transactionHashCb) {
    options.transactionHashCb(error)
  }
  throw error
}

export type TxHashCallback = (hash: string) => () => void
export const transactionPromiseAndCallback = (callback: TxHashCallback) => {
  let txCallback
  const txPromise = new Promise((resolve, reject) => {
    txCallback = (error: unknown, hash: string): void => {
      if (error) {
        reject(error)
      } else {
        let clearCallback
        if (callback) {
          clearCallback = callback(hash)
        }
        resolve({
          transactionHash: hash,
          clearCallback,
        })
      }
    }
  })
  return [txPromise, txCallback]
}

export const sendWithCustomGasPrice = async (
  dvf: DvfClientInstance,
  abi: AbiItem,
  address: string,
  action: string,
  args: unknown[],
  value: null | string,
  options: {
    chain?: string
    gasLimit?: number
    transactionHashCb?: (_: unknown) => void
    customChainGasFeed?: ChainGasFeed
    isDeploy?: boolean
    customGasMultiplier?: number
    deployOptions?: {
      data: string
      arguments: (string | number)[]
    }
  } = {},
) => {
  const { DEFAULT: web3 } = getWeb3()

  let method = null

  if (options.isDeploy && options.deployOptions) {
    const contract = new web3.eth.Contract(abi)
    method = contract.deploy(options.deployOptions)
  } else {
    const contract = new web3.eth.Contract(abi, address)
    method = contract.methods[action](...args)
  }

  const executionChainId = getNetworkIdForChainName(options.chain)
  const chainId = await web3.eth.getChainId()

  // cast to not modify flow. parseInt should be probably removed or chainId should not be used as a number in baseSendParams
  const chain = getChainNameForNetworkId(parseInt(chainId as unknown as string)) ?? 'ETHEREUM'

  const baseSendParams = {
    chainId: `0x${chainId.toString(16)}`,
    from: dvf.get('account'),
    value: isNull(value) ? undefined : value,
  }
  // If a 'gasLimit' is passed as parameter
  // use this 'gasLimit' unless we predict
  // that Metamask will use a lower gasLimit
  let gasLimit
  if (options.gasLimit) {
    try {
      const gasEstimate = await method.estimateGas(baseSendParams)
      // Is Metamask auto gas limit worse than ours ?
      if (gasEstimate * 1.5 > options.gasLimit) {
        gasLimit = options.gasLimit
      }
    } catch (error) {
      console.warn('Failed to estimate gas for tx', error)
    }
  }

  let params: {
    chainId: string
    from: string
    value?: string
    gasLimit?: number
    maxPriorityFeePerGas?: undefined
    maxFeePerGas?: undefined
    gasPrice?: string
  } = {
    ...baseSendParams,
    gasLimit,
  }
  // EIP1559 transactions for Ethereum only
  if (chain === 'ETHEREUM') {
    // Let Metamask determine the values of these params
    // Note: we cannot use our estimator EIP1559GasFeeEstimation
    // because Metamask injected web3 doesn't support getFeeHistory function
    params.maxPriorityFeePerGas = undefined
    params.maxFeePerGas = undefined
  } else {
    params.gasPrice = await getSafeGasPrice({
      chain: options.chain,
      customChainGasFeed: options.customChainGasFeed,
      customGasMultiplier: options.customGasMultiplier,
    })
  }

  /**
   * https://darcs.atlassian.net/browse/ENGAGE-526
   * When using TokenPocket with WalletConnect the web3 provider we receive from them is not working as expected.
   * The transaction is mined succesfully but we never receive the `receipt` event and it's blocking the UI flow.
   * The workaround below uses our own providers to poll the transaction receipt on a regular interval.
   * This logic is triggered using a setTimeout only after 5 sec as it's not needed in most cases.
   */
  return new Promise<{ transactionHash: string }>((resolve, reject) => {
    if (executionChainId && chainId !== Number(executionChainId)) {
      if (options.transactionHashCb) {
        options.transactionHashCb(new Error(BridgeErrorType.wrongNetwork))
      }
      return reject(new Error(BridgeErrorType.wrongNetwork))
    }
    let timeoutId: NodeJS.Timeout | null = null
    method
      .send(params)
      .on('transactionHash', (txHash: string) => {
        // only start after 5 sec as it's only required for TokenPocket with WalletConnect (on receipt never triggers in this case)
        timeoutId = setTimeout(() => {
          pollTxReceiptFromOurProvider(txHash, chain)
            .then((receipt) => resolve(receipt))
            .catch(makeErrorHandler(options))
        }, 5000)
        makeOnTransactionHashHandler(options)(txHash)
      })
      .on('receipt', (receipt: TransactionReceipt) => {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
        resolve(receipt)
      })
      .catch((error: unknown) => {
        if (options.transactionHashCb) {
          options.transactionHashCb(error)
        }
        reject(error)
      })
  })
}

type SendAndReportTxHashOptions = {
  chain?: string
  transactionHashCb?: TransactionHashCallbackType
}

export const sendAndReportTxHash = async (
  dvf: DvfClientInstance,
  abi: AbiItem | AbiItem[],
  address: string,
  action: string,
  args: unknown[],
  value: string,
  options: SendAndReportTxHashOptions = {},
) => {
  const web3 = getWeb3()
  const chain = options.chain || 'ETHEREUM'
  const contract = new web3[chain].eth.Contract(abi, address)

  const method = contract.methods[action](...args)

  const { id: chainId } = await dvf.eth.getNetwork(chain)
  return method
    .send({
      chainId: `0x${chainId.toString(16)}`,
      from: dvf.get('account'),
      value: value,
    })
    .on('transactionHash', makeOnTransactionHashHandler(options))
    .catch(makeErrorHandler(options))
}

export const approveTransaction = async (
  txParams: TransactionConfig & { gasLimit?: number },
  cb: (error: unknown, txParams?: TransactionConfig) => void,
  shouldIncreaseGasLimit: boolean,
) => {
  try {
    // cast to not modify flow. parseInt is probably not necessary here
    const chain = txParams.chainId
      ? getChainNameForNetworkId(parseInt((txParams.chainId as unknown as string) ?? '0')) ?? 'ETHEREUM'
      : 'ETHEREUM'
    const dvf = await getDvf()
    const web3 = getWeb3()

    txParams.maxFeePerGas = undefined
    txParams.maxPriorityFeePerGas = undefined
    txParams.gasLimit = await web3[chain].eth.estimateGas(txParams)

    if (shouldIncreaseGasLimit) {
      txParams.gasLimit *= 1.25
      txParams.gas = txParams.gasLimit
    }

    if (chain === 'ETHEREUM') {
      let resolveFromModal
      const modalPromise = new Promise((resolve) => (resolveFromModal = resolve))
      const resolveId = randomId()
      let gasPricePromises = dvf.get('gasPricePromises')
      if (!gasPricePromises) {
        gasPricePromises = {}
      }
      gasPricePromises[resolveId] = {
        resolve: resolveFromModal,
      }
      dvf.set('gasPricePromises', gasPricePromises)
      showGasPriceModal(txParams.gasLimit, resolveId, true)
      const maxPriorityFee = await modalPromise
      if (maxPriorityFee === 0) {
        return cb(new Error('User denied transaction'))
      }
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      txParams.maxPriorityFee = Math.floor(maxPriorityFee)
    } else {
      const gasPrice = await getSafeGasPriceForSidechain({ chain })
      txParams.gasPrice = web3.DEFAULT.utils.numberToHex(gasPrice.toString())
    }
    cb(null, txParams)
  } catch (error) {
    cb(error)
  }
}

export const fullWithdrawalRequest = async (starkKey: string, vaultId?: number) => {
  const dvf = await getDvf()
  const { DEFAULT: web3 } = getWeb3()
  const starkExContract = new web3.eth.Contract(starkExV2Abi as AbiItem[], dvf.config.DVF.starkExContractAddress)
  return starkExContract.methods.fullWithdrawalRequest(starkKey, vaultId).send({
    from: dvf.get('account'),
    value: '0',
  })
}

const isObjectWithCodeKey = (error: unknown): error is { code: number | string } => {
  return typeof error === 'object' && error !== null && 'code' in error
}

const isObjectWithDataKey = (error: unknown): error is { data: { originalError: { code: number | string } } } =>
  typeof error === 'object' && error !== null && 'data' in error

// Error 4902 indicates that the chain has not been added to the users wallet
// if it is not, then install it into the users wallet
// We want to rethrow other error cases
const isChainAddedToTheWallet = (error: unknown) => {
  const notAddedOnDesktop = isObjectWithCodeKey(error) && error.code === 4902

  // Reference: https://ethereum.stackexchange.com/questions/115312/wallet-addethereumchain-is-not-working-in-metamask-android-app
  // Works for the MM iOS app as well
  const notAddedOnMobile = isObjectWithDataKey(error) && error.data.originalError.code === 4902

  return notAddedOnDesktop || notAddedOnMobile
}

type SupportedNetworkId = keyof typeof addNetworkParametersPerNetworkId
const isSupportedNetworkId = (networkId: number | string): networkId is SupportedNetworkId => {
  return networkId in addNetworkParametersPerNetworkId
}
export const switchEVMWalletToNetworkId = async (networkId?: number | string) => {
  const provider = getProvider()
  if (!provider || 'initialProvider' in provider || !networkId) {
    return
  }
  const chainId = `0x${networkId.toString(16)}`
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }], // chainId must be in hexadecimal numbers
    })
  } catch (error) {
    if (!isChainAddedToTheWallet(error)) {
      throw error
    }

    const networkParameters = isSupportedNetworkId(networkId) ? addNetworkParametersPerNetworkId[networkId] : undefined
    if (!networkParameters) {
      throw new Error(`NO_NETWORK_PARAMETERS_FOR_NETWORK ${networkId}`)
    }

    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId,
          ...networkParameters,
        },
      ],
    })
  }
}

// Based on: https://docs.alchemy.com/alchemy/guides/eip-1559/gas-estimator
export const EIP1559GasFeeEstimation = async () => {
  const { DEFAULT: web3 } = getWeb3()
  const historicalBlocks = 20
  const feeHistory = await web3.eth.getFeeHistory(historicalBlocks, 'pending', [1, 50, 99])
  const blocks = map(feeHistory.gasUsedRatio, (item, index) => ({
    blockNumber: feeHistory.oldestBlock + index,
    baseFeePerGas: Number(feeHistory.baseFeePerGas[index]),
    gasUsedRatio: Number(index),
    priorityFeePerGas: feeHistory.reward[index].map((fee) => Number(fee)),
  }))

  // All values are saved in gwei
  const cheap = Math.ceil(mean(blocks.map((block) => block.priorityFeePerGas[0])) * 10 ** -9)
  const average = Math.ceil(mean(blocks.map((block) => block.priorityFeePerGas[1])) * 10 ** -9)
  const fast = Math.ceil(mean(blocks.map((block) => block.priorityFeePerGas[2])) * 10 ** -9)

  const currentBlockBaseFee = (await web3.eth.getBlock('pending')).baseFeePerGas || 0
  const baseFee = Number(currentBlockBaseFee * 10 ** -9)

  return {
    baseFee,
    cheap,
    average,
    fast,
  }
}
