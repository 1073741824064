import { getTronProvider } from '../../providers/tronProvider'

export const getTronNativeBalance = async (tronAddress: string) => {
  try {
    const { tronWeb } = getTronProvider()
    if (!tronAddress || !tronWeb) {
      throw new Error('Please connect your Tron wallet first.')
    }
    const balance = await tronWeb.trx.getBalance(tronAddress)
    return balance / 10 ** 6
  } catch (error) {
    console.error(error)
    throw error
  }
}
