import { TonClient } from '@ton/ton'
import type { TonConnectUI } from '@tonconnect/ui'
import { getChainRpcUrl } from '../../ethereum/chainProviders'
import { NETWORKS } from '../../../constants/types'

let tonWalletProvider: TonConnectUI | undefined
let tonPublicProvider: TonClient | undefined

export const setupTonProvider = (walletProvider: TonConnectUI) => {
  tonWalletProvider = walletProvider
  tonPublicProvider = new TonClient({
    endpoint: getChainRpcUrl(NETWORKS.TON),
  })
}

export const getTonProvider = () => {
  if (!tonWalletProvider) {
    throw new Error('Please connect your TON wallet first.')
  }
  return {
    walletProvider: tonWalletProvider,
    publicProvider: tonPublicProvider,
  }
}
