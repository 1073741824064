import merge from 'lodash/merge'
import _intersection from 'lodash/intersection'
import type { UnifiedTokenRegistryState } from '../../reducers/types/UnifiedTokenRegistryState'
import { getNativeBalance } from '../ethereumService'
import { isLpToken } from '../helperService/isLpToken'
import { getWeb3 } from '../wallets/wallet'
import { NETWORKS, NON_EVM_CHAINS } from '../../constants/types'
import { getStarknetBalances } from '../secondaryWallet/services/starknetService/getStarknetBalances'
import { getTronNativeBalance } from '../secondaryWallet/services/tronService/getTronNativeBalance'
import { getTronBalances } from '../secondaryWallet/services/tronService/getTronBalances'
import { tokenInfoForChain } from '../../utils/tokenInfoForChain'
import { getSolanaBalances } from '../secondaryWallet/services/solanaService/getSolanaBalances'
import { getSolanaNativeBalance } from '../secondaryWallet/services/solanaService/getSolanaNativeBalance'
import { getParadexBalances } from '../secondaryWallet/services/paradexService/getParadexBalances'
import { getTokenAddressesForChain } from '../helperService/getTokenAddressesForChain'
import { getCrossChainChainsFromRegistry } from '../helperService/getCrossChainChainsFromRegistry'
import { getTokenAddressesPerChainForTokens } from '../helperService/getTokenAddressesPerChainForTokens'
import { getTonNativeBalance } from '../secondaryWallet/services/tonService/getTonNativeBalance'
import { getTonBalances } from '../secondaryWallet/services/tonService/getTonBalances'
import { multiCallBalances } from './multicall'
import type { MulticallBalances } from './multicall'
import { getChainNativeToken, isAlternativeName } from './chainProviders'

export type AddressEntry = {
  address: string
  token: string
}

export const getBlockchainBalances = async ({
  address,
  secondaryWallet,
  tokenRegistry,
  enabledBridges,
  withBalancesTimeout = false,
}: {
  address: string
  secondaryWallet: { secondaryWalletAddress: string; connectedChain: string }
  tokenRegistry: UnifiedTokenRegistryState
  enabledBridges: string[]
  withBalancesTimeout?: boolean
}): Promise<{ blockchainBalance: MulticallBalances; failedChains: string[] }> => {
  const tokens = Object.keys(tokenRegistry)

  const filteredTokens = tokens.filter((token) => !isLpToken(token))

  const crossChainChains = getCrossChainChainsFromRegistry(tokenRegistry)
  const tokenAddressesPerChain = getTokenAddressesPerChainForTokens(filteredTokens, tokenRegistry)
  const bridgeChains = Object.keys(tokenAddressesPerChain)
  const enabledBridgeChains = _intersection(bridgeChains, enabledBridges)

  const chains = [...enabledBridgeChains, ...crossChainChains]
  const allChains = chains.filter((chain) => supportedChains(chain, secondaryWallet.connectedChain))

  const balancePromises = await Promise.allSettled(
    allChains.map((chain) =>
      getBlockchainBalanceForChain(chain, address, secondaryWallet, tokenRegistry, withBalancesTimeout),
    ),
  )

  const rejectedPromisesIndexes = balancePromises.map(({ status }, index) => (status === 'rejected' ? index : null))
  const failedChains = allChains
    .filter((_, index) => rejectedPromisesIndexes.includes(index))
    .filter((chain) => !isAlternativeName(chain))

  const balances = balancePromises.reduce((acc, promiseResult) => {
    if (promiseResult.status !== 'fulfilled') {
      return acc
    }
    const key = Object.keys(promiseResult.value)[0]
    acc[key] = promiseResult.value[key]
    return acc
  }, {} as MulticallBalances)

  return {
    blockchainBalance: balances,
    failedChains,
  }
}

export const supportedChains = (chain: string, connectedChain: string) => {
  const web3 = getWeb3()
  return !!web3[chain] || (NON_EVM_CHAINS[chain] && connectedChain === chain)
}

export const getBlockchainBalanceForChain = async (
  chain: string,
  address: string,
  secondaryWallet: { secondaryWalletAddress: string; connectedChain: string },
  tokenRegistry: UnifiedTokenRegistryState,
  withBalancesTimeout = false,
): Promise<MulticallBalances> => {
  const web3 = getWeb3()
  const nativeToken = getChainNativeToken(chain)
  const getTokenInfoForChain = tokenInfoForChain(tokenRegistry)

  const tokens = Object.keys(tokenRegistry)
  const filteredTokens = tokens.filter((token) => !isLpToken(token))

  const tokenAddressesForChain = getTokenAddressesForChain(filteredTokens, chain, tokenRegistry)

  if (NON_EVM_CHAINS[chain]) {
    if (chain === NETWORKS.STARKNET) {
      return getStarknetBalances(secondaryWallet.secondaryWalletAddress, tokenAddressesForChain, tokenRegistry, chain)
    } else if (chain === NETWORKS.TRON) {
      const erc20Balances = await getTronBalances(
        secondaryWallet.secondaryWalletAddress,
        tokenAddressesForChain,
        tokenRegistry,
      )
      const nativeBalance = await getTronNativeBalance(secondaryWallet.secondaryWalletAddress)

      return merge({}, erc20Balances, {
        [chain]: {
          [nativeToken]: {
            balance: nativeBalance,
            token: nativeToken,
          },
        },
      })
    } else if (chain === NETWORKS.SOLANA) {
      const erc20Balances = await getSolanaBalances(
        secondaryWallet.secondaryWalletAddress,
        tokenAddressesForChain,
        tokenRegistry,
      )
      const nativeBalance = await getSolanaNativeBalance(secondaryWallet.secondaryWalletAddress)
      return merge({}, erc20Balances, {
        [chain]: {
          [nativeToken]: {
            balance: nativeBalance,
            token: nativeToken,
          },
        },
      })
    } else if (chain === NETWORKS.PARADEX) {
      return getParadexBalances()
    } else if (chain === NETWORKS.TON) {
      const nativeBalance = await getTonNativeBalance(secondaryWallet.secondaryWalletAddress)
      const jettonBalances = await getTonBalances(
        secondaryWallet.secondaryWalletAddress,
        tokenAddressesForChain,
        tokenRegistry,
      )

      return merge({}, jettonBalances, {
        [chain]: {
          [nativeToken]: {
            balance: nativeBalance,
            token: nativeToken,
          },
        },
      })
    } else {
      return {}
    }
  } else {
    const tokenAddressesWithoutNative = tokenAddressesForChain.filter(({ token }) => token !== nativeToken)

    // ERC20 balances (not native token)
    const erc20Balances = await multiCallBalances(
      web3[chain],
      address,
      tokenAddressesWithoutNative,
      chain,
      tokenRegistry,
      withBalancesTimeout,
    )

    // Native token balance
    const tokenInfo = getTokenInfoForChain(nativeToken, chain)
    const nativeBalance = await getNativeBalance(address, chain, tokenInfo?.decimals ?? 18)

    return merge({}, erc20Balances, {
      [chain]: {
        [nativeToken]: {
          balance: nativeBalance,
          token: nativeToken,
        },
      },
    })
  }
}
