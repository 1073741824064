import { Address } from '@ton/core'
import Decimal from 'decimal.js'
import { getTonProvider } from '../../providers/tonProvider'
import { getChainNativeTokenDecimals } from '../../../ethereum/chainProviders'
import { NETWORKS } from '../../../../constants/types'

export const getTonNativeBalance = async (secondaryWalletAddress: string) => {
  const { publicProvider } = getTonProvider()

  if (!secondaryWalletAddress || !publicProvider) {
    throw new Error('Please connect your Ton wallet first.')
  }

  const tonAddress = Address.parse(secondaryWalletAddress)
  const decimals = getChainNativeTokenDecimals(NETWORKS.TON)
  const balance = (await publicProvider.getBalance(tonAddress)).toString()
  return new Decimal(balance).div(10 ** decimals).toString()
}
