import { Address } from '@ton/core'
import { JettonWallet } from '@ton/ton'
import type { AddressEntry } from '../../../ethereum/blockchainBalances'
import type { UnifiedTokenRegistryState } from '../../../../reducers/types/UnifiedTokenRegistryState'
import { getTonProvider } from '../../providers/tonProvider'
import { getChainNativeToken } from '../../../ethereum/chainProviders'
import { NETWORKS } from '../../../../constants/types'
import type { MulticallBalances } from '../../../ethereum/multicall'
import { SafeDecimal } from '../../../../utils/SafeDecimal'
import { tokenInfoForChain } from '../../../../utils/tokenInfoForChain'
import { getJettonWalletAddress } from './getJettonWalletAddress'

export const getTonBalances = async (
  secondaryWalletAddress: string,
  tokenAddresses: AddressEntry[],
  tokenRegistry: UnifiedTokenRegistryState,
) => {
  try {
    const { publicProvider } = getTonProvider()
    if (!secondaryWalletAddress || !publicProvider) {
      throw new Error('Please connect your Ton wallet first.')
    }
    const tonFormattedUserAddress = Address.parse(secondaryWalletAddress)

    const filteredAddresses = tokenAddresses.filter(({ token }) => token !== getChainNativeToken(NETWORKS.TRON))
    const balances = await Promise.all(
      filteredAddresses.map(async ({ address }) => {
        const jettonWalletAddress = await getJettonWalletAddress(tonFormattedUserAddress, Address.parse(address))

        const jettonWallet = publicProvider.open(JettonWallet.create(jettonWalletAddress))
        const balance = await jettonWallet.getBalance()
        return balance.toString()
      }),
    )

    return filteredAddresses.reduce((accumulator, { token }: { token: string }, index: number) => {
      if (!accumulator[NETWORKS.TON]) {
        accumulator[NETWORKS.TON] = {}
      }

      const balance = balances[index]
      const { decimals } = tokenInfoForChain(tokenRegistry)(token, NETWORKS.TON)
      accumulator[NETWORKS.TON][token] = {
        balance: SafeDecimal(balance)
          .div(10 ** decimals)
          .toString(),
        token,
      }
      return accumulator
    }, {} as MulticallBalances)
  } catch (error) {
    console.error(error)
  }
}
