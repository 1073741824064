import type { NftType as MissionNftType } from '@rhinofi/dvf-rhino-nation-api-spec'
import { chainKeyToName } from '../../../../../services/ethereum/chainProviders'
import type { NFTType } from '../../../../../services/nfts/mintNFT/mintNFT.types'
import { getNFTImage } from '../../../../ActivityTracker/ActivityTrackerNFT/assets'
import type { NFTMinter } from './NFTMinterAssets'

const getNFTName = (type: NFTType, chain: string) => {
  switch (type) {
    case 'wrapped2023':
      return 'rhino.fi Wrapped 2023'
    case 'hunter':
      return chain ? `rhino.fi ${chainKeyToName(chain)} Hunter` : 'rhino.fi Hunter'
    case 'TOP30':
      return chain ? `rhino.fi ${chainKeyToName(chain)} Pro Hunter` : 'rhino.fi Pro Hunter'
    case 'VOLUME':
      return chain ? `${chainKeyToName(chain)} Trader` : 'Trader'
    case 'TXCOUNT':
      return chain ? `${chainKeyToName(chain)} Frequent Flyer` : 'Frequent Flyer'
    case 'EXPLORER':
      return 'Starknet Explorer'
    case 'GOD':
      return 'Starknet God'
    case 'ALPHAKEY':
      return 'Rhino Alpha Key'
    case 'BETAKEY':
      return 'Rhino Passport'
    default:
      return ''
  }
}

export const getNftNameForMissionNFTType = ({ nftType, chain }: { nftType: MissionNftType; chain: string }) => {
  if (nftType === 'HUNTER') {
    return getNFTName('hunter', chain)
  }

  return getNFTName(nftType, chain)
}

export const getNFTMetaData = (type: NFTType, chain: string, tokenId: number): NFTMinter => ({
  name: getNFTName(type, chain),
  image: getNFTImage(type, chain),
  tokenId,
  chain,
  type,
})
