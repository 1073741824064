import Decimal from 'decimal.js'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'

import intersection from 'lodash/intersection'
import { dynamicT, translate } from '../intl/i18n'
import { firstInPair, lastInPair } from '../services/formatService'
import type { SupportedWallets } from '../constants/types'
import type { EnvConfig } from '../env/envConfig'
import { envConfig } from '../env/envConfig'
import { NETWORKS } from '../constants/types'
import { convertUSD } from '../utils/convertUSD'
import type { TickerState } from '../reducers/tickerSlice'
import { firstInPairPreserveCase, lastInPairPreserveCase } from './formatService'
import { getDvf } from './dvfClient'
import { getChainBlockExplorer } from './ethereum/chainProviders'
import { getBookV2, getGasPrices } from './apiService'
import type { TokenRegistry } from './apiService/getConfig.types'

const { cloudFlareWebAnalyticsScript } = envConfig

Decimal.set({
  rounding: Decimal.ROUND_DOWN,
  toExpPos: 9e15,
  toExpNeg: -9e15,
})

export const saveLastLogin = (lastLogin: { type: SupportedWallets; path?: string; address: string; name?: string }) => {
  localStorage.setItem('last-login', JSON.stringify(lastLogin))
}

export const getLastLogin = () => {
  const lastLogin = localStorage.getItem('last-login')
  return lastLogin ? JSON.parse<{ type: string; path?: string; name?: string }>(lastLogin) : { type: 'none' }
}

export const invertPrice = (price: number) => {
  if (price === 0) {
    return price
  }
  return precise(1 / price)
}

export const precise = (num: number | string, precision = 5) => {
  return Number.parseFloat(Number.parseFloat(num.toString()).toPrecision(precision))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- any comes from upstream
export const parseWalletError = (anyError: any) => {
  let error = anyError.message || (typeof anyError === 'string' && anyError) || ''
  if (error.indexOf('Metamask+Ledger2') >= 0) {
    return translate('errors.metamask_ledger2')
  }
  if (error.indexOf('Metamask+Ledger') >= 0) {
    return translate('errors.metamask_ledger')
  }
  if (error.toLowerCase().indexOf('failed to fetch') >= 0) {
    return translate('errors.failed_to_fetch')
  }
  if (error.indexOf('denied transaction') >= 0) {
    return translate('errors.user_denied_tx')
  }
  if (error.indexOf('MetaMask') >= 0) {
    if (error.indexOf('\n') >= 0) {
      error = error.substr(0, error.indexOf('\n'))
    }
    return error.replace('Returned error: Error: ', '')
  }
  if (error.indexOf('0x6700') >= 0) {
    return translate('global.error_ledger_eth')
  }
  if (error.indexOf('6801') >= 0 || error.indexOf('6804') >= 0 || error.indexOf('0x6b0c') >= 0) {
    return translate('global.error_ledger_locked')
  }
  if (error.indexOf('6a80') >= 0) {
    return translate('global.error_ledger_contract_data')
  }
  if (error.indexOf('6985') >= 0) {
    return translate('global.error_ledger_confirmation_denied')
  }
  if (error === 'Sign failed') {
    return translate('global.error_ledger_timed_out')
  }
  if (error === 'Uncaught Error: Key derivation failed - possibly wrong passphrase') {
    return translate('global.error_wrong_password')
  }
  if (['_context3.t0 is not a constructor', 'e.t0 is not a constructor'].includes(error)) {
    return translate('errors.wallet_connection_cancelled')
  }
  if (error.indexOf('eth_requestAccounts') >= 0) {
    return translate('global.metamask_locked')
  }
  if (error.indexOf('User closed modal') >= 0 || error.indexOf('User denied account authorization') >= 0) {
    return ''
  }
  if (error.indexOf('wallet_requestPermissions') >= 0) {
    return translate('global.metamask_wallet_connection_pending')
  }
  return dynamicT(error)
}

const getTransactionSlug = (chain: string) => {
  if (chain === NETWORKS.TRON) {
    return '#/transaction'
  } else if (chain === NETWORKS.TON) {
    return 'transaction'
  } else {
    return 'tx'
  }
}

const formatTargetHash = (target: string, chain: string) => {
  if (chain === NETWORKS.TON) {
    const targetParsed = target.startsWith('0x') ? target.substring(2) : target
    return targetParsed.replace(/\+/g, '-').replace(/\//g, '_')
  }
  return target
}

export const etherscanLink = (target: string, chain = 'ETHEREUM', isTransaction = false) => {
  const targetLength = chain === NETWORKS.STARKNET || chain === NETWORKS.PARADEX ? 65 : 42
  const addressFieldLabel = chain === NETWORKS.STARKNET || chain === NETWORKS.PARADEX ? 'contract' : 'address'
  const type = target.length >= targetLength || isTransaction ? getTransactionSlug(chain) : addressFieldLabel
  const blockExplorerUrl = getChainBlockExplorer(chain)
  return `${blockExplorerUrl}/${type}/${encodeURIComponent(formatTargetHash(target, chain))}`
}

export const getSettleSpread = (registry: TokenRegistry) => {
  if (registry && registry.USDT) {
    return registry.USDT.settleSpread || 0
  }
  return 0
}

// Inverted flag is for flipped pairs
export const alterPrice = (pair: string, price: number, settleSpread?: unknown, inverted?: boolean) => {
  let isDAI = inverted ? lastInPair(pair, true) === 'DAI' : firstInPair(pair, true) === 'DAI'
  let _price = isDAI ? invertPrice(price) : price
  return _price || 0
}

export const formatSubmitPair = (pair?: string) =>
  `${convertUSD(firstInPairPreserveCase(pair))}:${convertUSD(lastInPairPreserveCase(pair))}`

export const timeSince = (date: Date | number) => {
  // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
  const seconds = Math.floor((new Date() - date) / 1000)
  let interval = Math.floor(seconds / 31536000)

  if (interval > 1) {
    return interval + ` ${translate('helpers.years')} ${translate('helpers.ago')}`
  }
  interval = Math.floor(seconds / 2592000)
  if (interval > 1) {
    return interval + ` ${translate('helpers.months')} ${translate('helpers.ago')}`
  }
  interval = Math.floor(seconds / 86400)
  if (interval > 1) {
    return interval + ` ${translate('helpers.days')} ${translate('helpers.ago')}`
  }
  interval = Math.floor(seconds / 3600)
  if (interval > 1) {
    return interval + ` ${translate('helpers.hours')} ${translate('helpers.ago')}`
  }
  interval = Math.floor(seconds / 60)
  if (interval > 1) {
    return interval + ` ${translate('helpers.minutes')} ${translate('helpers.ago')}`
  }
  return Math.floor(seconds) + ` ${translate('helpers.seconds')} ${translate('helpers.ago')}`
}

export const downloadBlob = (blob: Blob, filename: string) => {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob)
  // Create a new anchor element
  const anchor = document.createElement('a')
  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  anchor.href = url
  anchor.download = filename || 'download'
  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      anchor.removeEventListener('click', clickHandler)
    }, 150)
  }
  // Add the click event listener on the anchor element
  // Comment out this line if you don't want a one-off download of the blob content
  anchor.addEventListener('click', clickHandler, false)
  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  anchor.click()
  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return anchor
}

export const getConfigKey = <T extends keyof EnvConfig>(key: T): EnvConfig[T] => {
  const fromLocalStorage = localStorage.getItem(key)
  return fromLocalStorage ? JSON.parse<EnvConfig[T]>(fromLocalStorage) || envConfig[key] : envConfig[key]
}
// Only sources enabled in config.xxx.js with show up, unless an 'enabledSources'
// entry in localStorage overrides it (for testing)
export const getEnabledSources = () => getConfigKey('enabledSources')

export const sourcesFromTokenAddressesPerChain = (tokenAddressesPerChain: Record<string, unknown>) => {
  const availableSourcesForToken = Object.keys(tokenAddressesPerChain)
  return intersection(availableSourcesForToken, getEnabledSources())
}

export const getTickerForToken = <T>(ticker: Record<string, T>, token: string) => {
  const _token = token.toUpperCase() === 'XDVF' ? 'DVF' : token
  return (
    ticker[`${_token}:USDT`] ||
    ticker[`${_token}:UST`] ||
    ticker[`${_token}:USDC`] ||
    ticker[`${_token}:ETH`] ||
    ticker[`${_token}:BTC`] ||
    null
  )
}

type TickersWithPrice = Record<string, TickerState[string] & { lastPriceUSD?: number }>
export type TickersWithEnsuredPrice = Record<string, TickerState[string] & { lastPriceUSD: number }>
export const addUsdPriceToTickers = (ticker: TickerState): TickersWithEnsuredPrice => {
  const _ticker: TickersWithPrice = cloneDeep(ticker)

  Object.keys(ticker).forEach((pair) => {
    const baseCcy = lastInPair(pair, true)
    _ticker[pair].lastPriceUSD = 1
    if (baseCcy === 'ETH') {
      _ticker[pair].lastPriceUSD = ticker['ETH:USDT']?.lastPrice || 1
    }
    if (baseCcy === 'DAI') {
      _ticker[pair].lastPriceUSD = 1 / ticker['DAI:USDT']?.lastPrice || 1
    }
    if (baseCcy === 'BTC') {
      _ticker[pair].lastPriceUSD = ticker['BTC:USDT']?.lastPrice || 1
    }
  })
  // Legacy: cast to avoid altering the flow
  return _ticker as TickersWithEnsuredPrice
}

export const capitalize = (value?: string) => (value ? value.replace(/\b\w/g, (letter) => letter.toUpperCase()) : '')

export const convertMtsToLabel = (mts?: string) => {
  if (!mts) {
    return ''
  }
  const date = new Date(parseInt(mts))
  const currentDate = new Date()
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const month = months[date.getMonth()]
  if (date.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)) {
    return `${date.getDate()} ${month}`
  }
  return moment(parseInt(mts)).format('HH:mm')
}

export const timestampToHumanTime = (timestamp: undefined | number | string) =>
  moment(timestamp).format('MMMM Do YYYY, h:mm:ss a')

export const getUniswapTradeCostUsd = async () => {
  const gasPrices = (await getGasPrices()) || { fast: 100 }
  const askData = (await getBookV2('ETH:USDT'))?.asks?.[0]?.price

  const ask = askData || 3000

  return (ask * 190000 * parseFloat(gasPrices.fast)) / 10 ** 18
}

export const isSafariIOS = () => {
  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  const webkit = !!ua.match(/WebKit/i)
  return iOS && webkit && !ua.match(/CriOS/i)
}

export const initCloudflareScrypt = () => {
  if (cloudFlareWebAnalyticsScript) {
    const script = document.createElement('script')
    // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    script.setAttribute('defer', true)
    script.setAttribute('src', 'https://static.cloudflareinsights.com/beacon.min.js')
    script.setAttribute('data-cf-beacon', '{"token": "588ccd445aa643d4b0f136a303d3a154"}')
    document.body.appendChild(script)
  }
}

export const isBridgeChain = (chain: string) => chain !== 'ETHEREUM' && getEnabledSources().includes(chain)

export const fromQuantizedAmountAsync = async (token: string, amount: number | string) => {
  const dvf = await getDvf()
  return dvf.token.fromQuantizedAmount(token, amount)
}

export const dailyChangeToStatus = (change: string | number) => {
  const dailyChange = parseFloat(change.toString())
  if (dailyChange === 0) {
    return 'none'
  } else if (dailyChange > 0) {
    return 'buy'
  } else if (dailyChange < 0) {
    return 'sell'
  }
}

export const tokenRegistryForChain = async (token: string, chain?: string) => {
  const dvf = await getDvf()

  return dvf.token.getTokenInfoForChainOrThrow(token, chain)
}

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return typeof error === 'object' && error != null && 'message' in error && typeof error.message === 'string'
}

export const getRTKErrMessage = (err: unknown) => {
  if (!err) {
    return ''
  }

  if (isFetchBaseQueryError(err)) {
    return 'error' in err ? err.error.toString() : JSON.stringify(err.data)
  } else if (isErrorWithMessage(err)) {
    return err.message
  }

  return 'Error has occurred'
}

export const hex2rgb = (hex: string): number[] => {
  const red = parseInt(hex.slice(1, 3), 16)
  const green = parseInt(hex.slice(3, 5), 16)
  const blue = parseInt(hex.slice(5, 7), 16)

  return [red, green, blue]
}

export const errorToString = (error: unknown) => {
  if (typeof error === 'string') {
    return error
  }
  if (error instanceof Error) {
    return error.message
  }
  if (error instanceof Object) {
    return JSON.stringify(error)
  }
  return 'Unknown error'
}
