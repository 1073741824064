export async function runEffectHttpCallback<T>(
  callback: () => Promise<T>,
  noData: true,
): Promise<{ data: undefined } | { error: { status: number; data: unknown } }>
export async function runEffectHttpCallback<T>(
  callback: () => Promise<T>,
): Promise<{ data: T } | { error: { status: number; data: unknown } }>

export async function runEffectHttpCallback<T>(callback: () => Promise<T>, noData?: boolean) {
  try {
    const result = await callback()
    if (noData) {
      return {
        data: undefined,
      }
    }
    return { data: result }
  } catch (error) {
    if (error && typeof error === 'object') {
      if ('cause' in error) {
        const cause = error.cause
        if (cause && typeof cause === 'object' && 'failure' in cause) {
          const failure = cause.failure
          if (failure && typeof failure === 'object' && 'message' in failure) {
            if ('status' in failure && typeof failure.status === 'number') {
              return { error: { status: failure.status, data: failure.message } }
            } else if ('side' in failure && failure.side === 'client') {
              return { error: { status: 400, data: failure.message } }
            }
          }
        }
      }

      if ('message' in error) {
        return { error: { status: 500, data: error.message } }
      }
    }

    return { error: { status: 500, data: error } }
  }
}
