import { InjectedConnector } from 'starknetkit/injected'
import { ArgentMobileConnector } from 'starknetkit/argentMobile'
import { connect } from 'starknetkit'
import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { shortString } from 'starknet'
import { isFeatureEnabled } from '../../helperService/isFeatureEnabled'
import { F_FLAGS_TYPES, NETWORKS } from '../../../constants/types'
import { WALLET_CONNECT_PROJECT_ID } from '../../wallets/connectors/webWallet'
import { setStarknetProvider } from '../providers/starknetProvider'
import { createStarknetListener } from '../services/starknetService/createStarknetListener'
import { starknetLookup } from '../../nameServices/starknetIdService'
import type { SecondaryConnectionPayload } from './connectors.types'

export const connectToStarknet = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<SecondaryConnectionPayload> => {
  const { wallet } = await connect({
    modalMode: 'alwaysAsk',
    dappName: 'rhino.fi',
    connectors: [
      new InjectedConnector({
        options: { id: 'argentX' },
      }),
      new InjectedConnector({
        options: { id: 'braavos' },
      }),
      ...(isFeatureEnabled(F_FLAGS_TYPES.STARKNET_ARGENT_MOBILE)
        ? [
            new ArgentMobileConnector({
              argentMobileOptions: { projectId: WALLET_CONNECT_PROJECT_ID },
            }),
          ]
        : []),
    ],
  })

  if (!wallet || !wallet.selectedAddress) {
    throw new Error('Something went wrong when connecting your Starknet wallet.')
  }
  setStarknetProvider(wallet)
  createStarknetListener(dispatch, wallet)
  const rawNetworkId = await wallet.account.getChainId()
  const networkId = shortString.decodeShortString(rawNetworkId)

  let domain = ''
  if (wallet.selectedAddress) {
    domain = await starknetLookup(wallet.selectedAddress)
  }

  return {
    address: wallet.selectedAddress,
    networkId: networkId,
    domain,
    connectedChain: NETWORKS.STARKNET,
  }
}
