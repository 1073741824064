import { NETWORKS } from '../../constants/types'
import { getBridgeContractAddressForChain } from '../ethereumService'
import { getDvf } from '../dvfClient'
import { approveTronDeposit } from './services/tronService/approveTronDeposit'

export const approveNonEVMDeposit = async (token: string, chain: string) => {
  const dvf = await getDvf()
  const tokenInfo = dvf.token.getTokenInfoForChainOrThrow(token, chain)
  const tokenAddressForChain = tokenInfo.tokenAddressPerChain[chain]
  const bridgedDepositContract = await getBridgeContractAddressForChain(chain)
  if (chain === NETWORKS.TRON) {
    return approveTronDeposit(tokenAddressForChain, bridgedDepositContract)
  } else {
    throw new Error('Approvals not available for this chain.')
  }
}
