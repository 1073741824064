import { PublicKey } from '@solana/web3.js'
import { getAssociatedTokenAddressSync, getAccount } from '@solana/spl-token'
import Decimal from 'decimal.js'
import { getSolanaConnection } from '../../providers/solanaProvider'
import type { AddressEntry } from '../../../ethereum/blockchainBalances'
import type { UnifiedTokenRegistryState } from '../../../../reducers/types/UnifiedTokenRegistryState'
import { NETWORKS } from '../../../../constants/types'
import type { MulticallBalance } from '../../../ethereum/multicall'

export const getSolanaBalances = async (
  secondaryWalletAddress: string,
  tokenAddresses: AddressEntry[],
  tokenRegistry: UnifiedTokenRegistryState,
) => {
  const connection = getSolanaConnection()
  const balances = await Promise.allSettled(
    tokenAddresses.map(async ({ address, token }) => {
      const decimals = tokenRegistry[token]?.decimals || 18
      const tokenAccount = getAssociatedTokenAddressSync(new PublicKey(address), new PublicKey(secondaryWalletAddress))
      const info = await getAccount(connection, tokenAccount)
      return { balance: new Decimal(info.amount.toString()).div(10 ** decimals).toString(), token }
    }),
  )
  const parsedBalances = balances.reduce(
    (accumulator, promiseResult) => {
      if (promiseResult.status !== 'fulfilled') {
        return accumulator
      }
      const { value } = promiseResult
      accumulator[value.token] = {
        balance: value.balance,
        token: value.token,
      }
      return accumulator
    },
    {} as Record<string, MulticallBalance>,
  )
  return {
    [NETWORKS.SOLANA]: parsedBalances,
  }
}
