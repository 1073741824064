import Decimal from 'decimal.js'
import type { AddressEntry } from '../../../ethereum/blockchainBalances'
import type { UnifiedTokenRegistryState } from '../../../../reducers/types/UnifiedTokenRegistryState'
import { getChainNativeToken } from '../../../ethereum/chainProviders'
import { NETWORKS } from '../../../../constants/types'
import type { MulticallBalances } from '../../../ethereum/multicall'
import { executeViewTronCall } from '../starknetService/executeViewTronCall'
import { parseTronResult } from './parseTronResult'

export const getTronBalances = async (
  secondaryWalletAddress: string,
  tokenAddresses: AddressEntry[],
  tokenRegistry: UnifiedTokenRegistryState,
) => {
  if (!secondaryWalletAddress) {
    return {}
  }
  try {
    const filteredAddresses = tokenAddresses.filter(({ token }) => token !== getChainNativeToken(NETWORKS.TRON))
    const balances = await Promise.all(
      filteredAddresses.map(async ({ address }) => {
        const result = await executeViewTronCall(address, 'balanceOf(address)', [
          { type: 'address', value: secondaryWalletAddress },
        ])
        if (result && result.length > 0) {
          return parseTronResult(result)
        } else {
          return '0'
        }
      }),
    )
    return filteredAddresses.reduce((accumulator, { token }: { token: string }, index: number) => {
      if (!accumulator[NETWORKS.TRON]) {
        accumulator[NETWORKS.TRON] = {}
      }
      const result = balances[index]
      const decimals = tokenRegistry[token]?.decimals || 18
      accumulator[NETWORKS.TRON][token] = {
        balance: new Decimal(result).div(10 ** decimals).toString(),
        token,
      }
      return accumulator
    }, {} as MulticallBalances)
  } catch (error) {
    console.error(error)
    return {}
  }
}
