import pickBy from 'lodash/pickBy'
import { translate } from '../../intl/i18n'
import { envConfig } from '../../env/envConfig'
import type { ChainGasFeed } from '../../constants/types'
import { NETWORKS, NON_EVM_CHAINS } from '../../constants/types'

export type ProviderConfig = {
  displayName: string
  networkName: string
  networkId: number | string
  blockExplorer: string
  confirmationBlocks: number
  rpcUrl: string
  gasFeed?: string | number
  gasMultiplier?: number
  alternativeNames?: string[]
  nativeTokenSafeguard: number
  avgBlockTime: number
  website?: string
}

type NetworkParameters = {
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
}

export const {
  chainProviders,
  addNetworkParametersPerNetworkId,
}: {
  chainProviders: Record<string, ProviderConfig>
  addNetworkParametersPerNetworkId: Record<number | string, NetworkParameters>
} = envConfig

export const { ETHEREUM: defaultEVMProvider, ...sidechainProviders } = chainProviders
export const evmSideChainProviders = pickBy(sidechainProviders, (value, key) => !NON_EVM_CHAINS[key])

const chainNameLookup = Object.entries(chainProviders).reduce(
  (acc, [chain, config]) => {
    const { alternativeNames = [] } = config
    acc[chain] = chain
    alternativeNames.forEach((name) => {
      if (acc[name]) {
        console.error(`Duplicate alternative name for chain ${chain}, duplicate ${name}`)
        return
      }
      acc[name] = chain
    })
    return acc
  },
  {} as Record<string, string>,
)

const networkIdLookup = Object.entries(chainProviders).reduce(
  (acc, [chain, config]) => {
    const { networkId } = config
    if (acc[networkId]) {
      console.error(`Duplicate networkId for chain ${chain}, duplicate ${networkId}`)
      return acc
    }
    acc[networkId] = chain
    return acc
  },
  {} as Record<number | string, string>,
)

export const getChainRpcUrl = (chain: string): string => {
  const chainName = chainNameLookup[chain] ?? ''
  return chainProviders[chainName]?.rpcUrl
}

export const getChainGasFeed = (chain: string): ChainGasFeed => {
  const chainName = chainNameLookup[chain] ?? ''
  return chainProviders[chainName]?.gasFeed
}

export const getChainGasMultiplier = (chain: string): number => {
  const chainName = chainNameLookup[chain] ?? ''
  return chainProviders[chainName]?.gasMultiplier ?? 1.1
}

export const getChainConfirmationBlocks = (chain: string): number => {
  const chainName = chainNameLookup[chain] ?? 'ETHEREUM'
  return chainProviders[chainName]?.confirmationBlocks
}

export const getChainBlockTime = (chain: string) => {
  const chainName = chainNameLookup[chain] ?? 'ETHEREUM'
  return chainProviders[chainName]?.avgBlockTime || 0
}

export const getChainBlockExplorer = (chain: string): string => {
  const chainName = chainNameLookup[chain] ?? 'ETHEREUM'
  return chainProviders[chainName]?.blockExplorer
}

// Returns network id for chain including alternativeNames
export const getNetworkIdForChainName = (chain?: string | null): number | string | undefined => {
  const chainName = chainNameLookup[chain || ''] ?? ''
  return chainProviders[chainName]?.networkId
}

// Always returns base name for chain (not alternativeNames)
export const getChainNameForNetworkId = (networkId: number | string): string | undefined => networkIdLookup[networkId]

export const isAlternativeName = (chain: string): boolean => {
  const alternativeNames = Object.values(chainProviders).reduce((acc, config) => {
    const { alternativeNames: alternativeNamesFromConfig = [] } = config
    return [...acc, ...alternativeNamesFromConfig]
  }, [] as string[])
  return alternativeNames.includes(chain)
}

export const getChainNativeTokenDecimals = (chain: string): number => {
  const networkId = getNetworkIdForChainName(chain)
  if (!networkId) {
    return 18
  }
  return addNetworkParametersPerNetworkId[networkId]?.nativeCurrency?.decimals || 18
}

export const getChainNativeToken = (chain: string): string => {
  const networkId = getNetworkIdForChainName(chain)
  if (!networkId) {
    return ''
  }
  return addNetworkParametersPerNetworkId[networkId]?.nativeCurrency?.symbol || 'ETH'
}

export const getChainFullName = (chain: string): string => {
  const networkId = getNetworkIdForChainName(chain)
  if (!networkId) {
    console.error('Failed to find network ID for chain', chain)
    return ''
  }
  return addNetworkParametersPerNetworkId[networkId]?.chainName || 'Ethereum'
}

export const getChainNativeSafeguard = (chain: string): number => {
  const chainName = chainNameLookup[chain] ?? ''
  return chainProviders[chainName]?.nativeTokenSafeguard ?? 0
}

export const chainKeyToName = (chain: string) => {
  if (chain === NETWORKS.STARKEX) {
    return 'Ethereum'
  }
  const chainName = chainNameLookup[chain] ?? ''
  return chainProviders[chainName]?.displayName || translate('helpers.not_available')
}

export const isNonEVMChain = (chain: string) => {
  return !!NON_EVM_CHAINS[chain]
}
