import Decimal from 'decimal.js'

export const SafeDecimal = (
  numberOrString: number | string | undefined | null | bigint | Decimal,
  customConfig?: Decimal.Config,
) => {
  const DecimalInstance = customConfig ? Decimal.clone(customConfig) : Decimal
  if (
    numberOrString === undefined ||
    numberOrString === null ||
    (typeof numberOrString === 'string' && (numberOrString.includes('NaN') || numberOrString.includes('Infinity'))) ||
    (typeof numberOrString === 'number' && (Number.isNaN(numberOrString) || !Number.isFinite(numberOrString)))
  ) {
    return new DecimalInstance(0)
  }

  try {
    return new DecimalInstance(numberOrString.toString())
  } catch (error) {
    return new DecimalInstance(0)
  }
}
