import type { Address } from '@ton/core'
import { JettonMaster } from '@ton/ton'
import { getTonProvider } from '../../providers/tonProvider'

export const getJettonWalletAddress = async (tonWalletAddress: Address, jettonMasterAddress: Address) => {
  const { publicProvider } = getTonProvider()

  if (!publicProvider) {
    throw new Error('Please connect your TON wallet first')
  }

  const jettonMaster = publicProvider.open(JettonMaster.create(jettonMasterAddress))

  return jettonMaster.getWalletAddress(tonWalletAddress)
}
