import type { NftType as NFTTypeSignature, StarknetNftType } from '@rhinofi/dvf-activity-trackers-api-spec'
import type { SingleNFTConfig } from './mintNFT.schemas'

export type EVMNFTType = 'hunter' | 'wrapped2023' | NFTTypeSignature | 'BETAKEY'
export type NFTType = EVMNFTType | StarknetNftType

export const isEVMNFTType = (nftType: NFTType): nftType is EVMNFTType => !['EXPLORER', 'GOD'].includes(nftType)

export type MintFunction = (
  userAddress: string,
  chain: string,
  onTransactionHash: (txHash: string) => void,
) => Promise<void>

export type MintFunctionFactory = (nftConfig: SingleNFTConfig, nftType: NFTType) => MintFunction
