import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { setupTronProvider } from '../providers/tronProvider'
import { NETWORKS } from '../../../constants/types'
import { getNetworkIdForChainName } from '../../ethereum/chainProviders'
import { createTronListener } from '../services/tronService/createTronListener'
import type { SecondaryConnectionPayload } from './connectors.types'

export const connectToTron = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<SecondaryConnectionPayload> => {
  const provider = await setupTronProvider()
  const address = provider && provider.tronWeb ? provider.tronWeb.defaultAddress.base58 : ''
  if (!provider || !address) {
    throw new Error('Something went wrong when connecting your Tron wallet.')
  }
  createTronListener(dispatch)
  return {
    address,
    networkId: (getNetworkIdForChainName(NETWORKS.TRON) || '').toString(),
    domain: '',
    connectedChain: NETWORKS.TRON,
  }
}
