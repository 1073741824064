import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { setupSolanaProvider } from '../providers/solanaProvider'
import { NETWORKS } from '../../../constants/types'
import { getNetworkIdForChainName } from '../../ethereum/chainProviders'
import { createSolanaListener } from '../services/solanaService/createSolanaListener'
import type { SecondaryConnectionPayload } from './connectors.types'

export const connectToSolana = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<SecondaryConnectionPayload> => {
  const provider = await setupSolanaProvider()
  await provider?.connect()
  const address = provider?.publicKey?.toString()
  if (!provider || !address) {
    throw new Error('Something went wrong when connecting your Tron wallet.')
  }
  createSolanaListener(dispatch, provider)
  return {
    address,
    networkId: (getNetworkIdForChainName(NETWORKS.SOLANA) || '').toString(),
    domain: '',
    connectedChain: NETWORKS.SOLANA,
  }
}
