import { disconnect } from 'starknetkit'
import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { getStarknetProvider } from '../../providers/starknetProvider'
import { disconnectSecondaryWallet } from '../../secondaryWalletSlice'

export const disconnectStarknetWallet = async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  try {
    const starknetProvider = getStarknetProvider()
    if (starknetProvider) {
      await disconnect({ clearLastWallet: true })
      dispatch(disconnectSecondaryWallet())
    }
  } catch (error) {}
}
