import * as S from '@effect/schema/Schema'

export const NFTContractTypeEnum = {
  NFTMinter: 'NFTMinter',
  NFTReward: 'NFTReward',
  RhinoFiWrappedNFT: 'RhinoFiWrappedNFT',
  RhinoERC1155: 'RhinoERC1155',
} as const
export type NFTContractTypeEnum = typeof NFTContractTypeEnum

export const NFTContractTypeSchema = S.Enums(NFTContractTypeEnum)
export type NFTContractType = typeof NFTContractTypeSchema.Type

export const SingleNFTConfigSchema = S.Struct({
  type: NFTContractTypeSchema,
  address: S.String,
})
export type SingleNFTConfig = typeof SingleNFTConfigSchema.Type

export const ERC1155SingleNFTConfigSchema = S.extend(
  SingleNFTConfigSchema,
  S.Struct({
    tokenId: S.Number,
    reducedFeesContract: S.optional(S.String),
    reducedFeesTokenId: S.optional(S.Number),
  }),
)
export type ERC1155SingleNFTConfig = typeof ERC1155SingleNFTConfigSchema.Type

export const NFTConfigSchema = S.Record({
  key: S.String,
  value: S.Record({
    key: S.String,
    value: SingleNFTConfigSchema,
  }),
})
